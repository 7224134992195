import { writable } from 'svelte/store';
import type { Stream } from '../interfaces/types';

export const streamsStore = writable<Stream[]>([]);
export const isStreamLoading = writable<boolean>(false);
export const currentStreams = writable<Stream[]>([]);
export const nextStreamPageUrl = writable<string | null>(null);
export const hasMoreStreams = writable<boolean>(false);

// Initialize the store with initial data
export function initializeStreamsStore(
    initialData: Stream[],
    nextUrl: string | null,
    hasMore: boolean
) {
    streamsStore.set(initialData);
    currentStreams.set(initialData);
    nextStreamPageUrl.set(nextUrl);
    hasMoreStreams.set(hasMore);
}

// Reset the store
export function resetStreamsStore() {
    streamsStore.set([]);
    currentStreams.set([]);
    nextStreamPageUrl.set(null);
    hasMoreStreams.set(false);
}
